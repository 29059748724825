module.exports = {
  siteTitle: 'James Stansbery', // <title>
  manifestName: 'James Stansbery',
  manifestShortName: 'James Stansbery', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: '', // This path is subpath of your hosting https://domain/portfolio
  heading: 'James Stansbery',
  address: 'Portland, Oregon',
  phone: null,
  // subHeading: 'Web and Mobile App Development | Project Management',
  subHeading: 'Software Engineer | Project Manager',
  // subHeading2: 'Mobile Applications | Web Applications | APIs',
  // social
  socialLinks: [
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      text: 'jstansbe@gmail.com',
      url: 'mailto:jstansbe@gmail.com',
    },
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      text: 'github.com/jstansbe',
      url: 'https://github.com/jstansbe',
    },
    // {
    //   style: 'brands',
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   text: '@stansbery',
    //   url: 'https://twitter.com/stansbery',
    // },
  ],
  skillSections: [
    {
      title: 'Server-Side' ,
      skills: ['C#', '.NET', 'Javascript', 'Node.js', 'Express.js', 'Hasura', 'Gatsby', 'Firebase', 'GraphQL', 'SQL Server', 'Oracle', 'Postgres']
    },
    {
      title: 'Front-End' ,
      skills: ['Javascript', 'React', 'React Native', 'Material-UI', 'Apollo', 'Datatables.js', 'CSS', 'HTML', 'Bootstrap', 'jQuery']
    },
    {
      title: 'Other' ,
      skills: ['Photoshop', 'Git', 'Github', 'Gitlab', 'Unity3d', 'Visual Studio', 'Visual Stude Code', 'Excel', 'PowerPoint']
    },
  ]
};
